import { Divider, Typography } from "@mui/material";
import React from "react";

import { H_misa } from "./H_misa";

export const Dia = ({ horario }) => {
  //Ordenar el horario por hora
  horario = horario.sort((a, b) => {
    return a.hora.localeCompare(b.hora);
  });

  return (
    <div className="div_dia">
      <Typography variant="h4" color={"primary.dark"} fontWeight={600}>
        {horario[0].dia}
      </Typography>
      <div className="div_horarios">
        {horario.map((horario) => {
          return <H_misa key={horario.id} horario={horario} />;
        })}
      </div>
      <Divider />
    </div>
  );
};

import React, { useState } from "react";

import moment from "moment/moment";
import { Button, CircularProgress, Tooltip, Typography } from "@mui/material";
import {
  Cake,
  Groups,
  Height,
  PhoneIphone,
  Email,
  ContentCopy,
} from "@mui/icons-material";

import { useFetchRol } from "../../../hooks/useFetchRol";
import { getLastRolAcolito } from "../../../helpers/getRoles";

export const Perfil = ({ acolito }) => {
  const {
    id,
    nombre_completo,
    fecha_nacimiento,
    cargo,
    celular,
    correo,
    estatura,
    equipo_actual,
  } = acolito;

  const [showTooltip, setShowTooltip] = useState(false);
  const [showNumber_phone, setShowNumber_phone] = useState(false);

  const { data: last_rol, loading } = useFetchRol(id, getLastRolAcolito);

  const handleCopy = (func, data) => {
    navigator.clipboard.writeText(data);
    func(true);
    setTimeout(() => {
      func(false);
    }, 1000);
  };

  if (loading === true) {
    return (
      <div className="div_loading_tabs">
        <CircularProgress />
      </div>
    );
  }

  const edad = moment().diff(fecha_nacimiento, "years");

  return (
    <div>
      <Typography variant="h6" fontWeight={600} marginBottom={1}>
        {cargo}
      </Typography>
      <div className="div_perfil_row_icon">
        <Groups color="secondary" sx={{ marginRight: 1 }} fontSize={"large"} />
        <Typography variant="body1">
          {equipo_actual ? equipo_actual : "Sin equipo asignado"}
        </Typography>
      </div>
      <div className="div_perfil_row_icon">
        <Height color="secondary" sx={{ marginRight: 1 }} fontSize={"large"} />
        <Typography variant="body1">{estatura} m</Typography>
      </div>
      <div className="div_perfil_row_icon">
        <Cake color="secondary" sx={{ marginRight: 1 }} fontSize={"large"} />
        <Typography variant="body1">{edad} años</Typography>
      </div>
      <div className="div_perfil_row_icon">
        <PhoneIphone
          color="secondary"
          sx={{ marginRight: 1 }}
          fontSize={"large"}
        />
        <Typography variant="body1">{celular}</Typography>
        <Button
          onClick={() => handleCopy(setShowNumber_phone, celular)}
          color="primary"
        >
          <Tooltip title="Copiado" placement="right" open={showNumber_phone}>
            <ContentCopy color="secondary" fontSize={"small"} />
          </Tooltip>
        </Button>
      </div>
      <div className="div_perfil_row_icon">
        <Email color="secondary" sx={{ marginRight: 1 }} fontSize={"large"} />
        <Typography variant="body1">{correo}</Typography>
        <Button
          onClick={() => handleCopy(setShowTooltip, correo)}
          color="primary"
        >
          <Tooltip title="Copiado" placement="top" open={showTooltip}>
            <ContentCopy color="secondary" fontSize={"small"} />
          </Tooltip>
        </Button>
      </div>

      <Typography
        variant="body1"
        fontStyle={"italic"}
        color={"secondary.dark"}
        marginTop={4}
        marginX={2}
        textAlign={"center"}
      >
        {
          // Si se tiene la fecha del ultimo rol, se muestra
          last_rol.dia !== undefined
            ? `Acolitó por última vez ${moment(
                last_rol.dia + " " + last_rol.hora
              ).fromNow()} en  ${last_rol.tipo_ceremonia}`
            : "No se tiene el registro de la última vez que acolitó"
        }
      </Typography>
    </div>
  );
};

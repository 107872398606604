import { useEffect, useState } from "react";
import { getPuestosAcolito } from "../helpers/getPuestos";

export const useFetchPuestosAcolito = (id_acolito) => {
  const [state, setState] = useState({
    data: [],
    loading: true,
  });

  useEffect(() => {
    getPuestosAcolito(id_acolito).then((puestos) => {
      setState({
        data: puestos,
        loading: false,
      });
    });
  }, []);

  return state;
};

import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import { Puestos } from "./Puestos/Puestos";
import { Perfil } from "./Perfil/Perfil";
import { Disponibilidad } from "./Disponibilidad/Disponibilidad";

export const Tabs = ({ acolito }) => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="div_tabs">
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
          }}
        >
          <TabList onChange={handleChange} centered>
            <Tab label="Disponibilidad" value="1" />
            <Tab label="Puestos" value="2" />
            <Tab label="Perfil" value="3" />
          </TabList>
        </Box>
        <TabPanel
          value="1"
          sx={{
            padding: 0,
            paddingTop: 3,
          }}
        >
          <Disponibilidad
            id_acolito={acolito.id}
            nombre_acolito={acolito.nombre}
          />
        </TabPanel>
        <TabPanel
          value="2"
          sx={{
            padding: 0,
            paddingTop: 3,
          }}
        >
          <Puestos id_acolito={acolito.id} />
        </TabPanel>
        <TabPanel value="3" sx={{ padding: 0, paddingTop: 3 }}>
          <Perfil acolito={acolito} />
        </TabPanel>
      </TabContext>
    </div>
  );
};

import { url } from "../api/url";

export const getDisponibilidad = async (id_acolito) => {
  try {
    const resp = await fetch(
      `${url}/acolitos/getHorarios.php?id=${id_acolito}`
    );
    const data = await resp.json();

    console.log(data);
    return data.data;
  } catch (error) {
    console.log("Error al obtener la disponibilidad del acólito", error);
  }
};

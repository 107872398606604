import React from "react";

import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { Typography } from "@mui/material";

export const No_results = ({ label = "No se encontró nada por aquí" }) => {
  return (
    <div className="div_no_puestos">
      <div className="div_icon_sad">
        <SentimentVeryDissatisfiedIcon
          color={"secondary"}
          sx={{ fontSize: 100 }}
        />
      </div>
      <Typography variant="h6" component="h6" color={"secondary.dark"}>
        {label}
      </Typography>
    </div>
  );
};

import { url } from "../api/url";

export const getCeremoniasByDate = async (fecha) => {
  try {
    const resp = await fetch(`${url}/ceremonias/get.php?fecha=${fecha}`);
    const data = await resp.json();
    return data;
  } catch (error) {
    console.log("Error al obtener las ceremonias", error);
  }
};

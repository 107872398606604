import React from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { Accordion_item } from "./Accordion_item";

export const Accordion_C = ({ Title, ...props }) => {
  const puestos = Object.values(props);

  const puestos_ordenados = [
    "Maestro de Ceremonias",
    "Turiferario",
    "Acólito 1",
    "Acólito 2",
    "CRUZ",
    "ACHA 1",
    "ACHA 2",
    "ACHA 3",
    "ACHA 4",
  ];

  let puestos_ordenados_array = [];

  puestos_ordenados.forEach((p) => {
    puestos.forEach((puesto) => {
      if (puesto.nombre_puesto === p) {
        puestos_ordenados_array.push(puesto);
      }
    });
  });

  return (
    <Accordion TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
        <Typography color={"primary"} fontWeight={700} fontSize={20}>
          {Title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {puestos_ordenados_array.map((puesto) => (
            <Accordion_item key={puesto.id} content={puesto.nombre_puesto} />
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

import { useEffect, useState } from "react";
import { getAcolitos_activos } from "../helpers/getAcolitos";

export const useFetchAcolitos_activos = () => {
  const [state, setState] = useState({
    data: [],
    loading: true,
  });

  useEffect(() => {
    getAcolitos_activos().then((acolitos) => {
      setState({
        data: acolitos,
        loading: false,
      });
    });
  }, []);

  return state;
};

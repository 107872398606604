import React from "react";
import { Avatar, Typography } from "@mui/material";

export const AvatarC = ({ name }) => {
  function stringAvatar(name) {
    const iniciales = `${name.split(" ")[0][0]}${
      name.split(" ")[name.split(" ").length - 2][0]
    }`;
    return iniciales;
  }

  return (
    <div className="div_avatar">
      <Avatar id="avatar">{stringAvatar(name)}</Avatar>

      <Typography
        variant="h3"
        fontWeight={700}
        color="primary"
        className="nombre_acolito"
      >
        {name}
      </Typography>
    </div>
  );
};

import { url } from "../api/url";

export const getLastRolAcolito = async (id_acolito) => {
  try {
    const resp = await fetch(`${url}/rol/getLastRol.php?id=${id_acolito}`);
    const data = await resp.json();
    return data;
  } catch (error) {
    console.log("Error al obtener el último rol del acolito", error);
  }
};

import { useEffect, useState } from "react";

export const useFetchRol = (id_acolito, gt) => {
  const [state, setState] = useState({
    data: [],
    loading: true,
  });

  useEffect(() => {
    gt(id_acolito).then((ac) => {
      setState({
        data: ac,
        loading: false,
      });
    });
  }, []);

  return state;
};

import React, { useRef, useState } from "react";
import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useFetchAcolitos_activos } from "../../hooks";
import { useNavigate } from "react-router-dom";

export const SearchBar = () => {
  const { data: acolitos, loading } = useFetchAcolitos_activos();
  const navigate = useNavigate();
  const myRef = useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView(true);

  return (
    <>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
          style={{ backgroundColor: "rgba(0,0,0, 0.9)" }}
        >
          <div className="div_loading">
            <CircularProgress color="primary" thickness={4} size={60} />
            <Typography variant="h6" className="txt_loading">
              Obteniendo información
            </Typography>
          </div>
        </Backdrop>
      )}
      <div className="div_searchbar" ref={myRef}>
        <Autocomplete
          fullWidth
          id="free-solo-2-demo"
          noOptionsText="Sin resultados"
          options={acolitos}
          getOptionLabel={(option) => option.nombre_completo}
          isOptionEqualToValue={(option, value) =>
            option.nombre_completo === value.nombre_completo
          }
          renderInput={(params) => (
            <TextField
              {...params}
              helperText="Buscar por nombre"
              variant="outlined"
              className="searchbar"
              label="Ingresa el nombre del acólito"
              onSelect={executeScroll}
            />
          )}
          onChange={(e, value) => {
            if (value) {
              navigate(`/acolito/${value.id}`);
            }
          }}
        />
      </div>
    </>
  );
};

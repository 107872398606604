import React from "react";
import moment from "moment";

import { useParams } from "react-router-dom";
import { Typography } from "@mui/material";

import { useFetchAcolitosByRol } from "../../hooks/useFetchAcolitosByRol";
import { Loading } from "../components/Loading";
import { ItemResult } from "../components/ItemResult";
import { useFetchPuesto } from "../../hooks/useFetchPuesto";
import "./findpage.css";
import { No_results } from "../../common/components/No_results";

export const FindPage = () => {
  //Asignar el nombre de la pestaña
  document.title = "Encontrar sustituto";

  const { date, horario, puesto } = useParams();

  const { data: nombre_puesto, loading } = useFetchPuesto(puesto);

  //Dia en formato DD/MM/YYYY
  const dia = moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");

  //Dia en formato YYYY-MM-DD
  const dia2 = moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");

  //Obtener el número de la dia de la semana
  const id_dia = moment(date).day();

  const { data: resultados, loading: loading_resultados } =
    useFetchAcolitosByRol(id_dia, dia2, horario, puesto);

  if (loading_resultados === true) {
    return <Loading items={5} />;
  }

  if (resultados.length === 0) {
    return (
      <div
      // style={{
      //   backgroundColor: "aqua",
      //   justifyContent: "center",
      //   display: "flex",
      //   alignItems: "center",
      // }}
      >
        <No_results label="No hay sustitutos disponibles" />
      </div>
    );
  }

  return (
    <div className="div_filterBar_container">
      <div className="div_info_busqueda">
        <Typography variant="h5" color={"secondary"}>
          {dia}
        </Typography>
        <Typography variant="h6" color={"secondary"} marginX={1}>
          -
        </Typography>
        <Typography variant="h6" color={"secondary"}>
          {horario}
        </Typography>
        <Typography variant="h6" color={"secondary"} marginX={1}>
          -
        </Typography>
        <Typography variant="h6" color={"secondary"}>
          {nombre_puesto[0]?.abreviatura || "Cargando..."}
        </Typography>
      </div>
      <div className="div_txt_find_sustituto">
        <Typography variant="h4" color={"secondary.dark"} fontWeight={600}>
          Acólitos disponibles
        </Typography>
      </div>

      {resultados.map((r) => {
        return <ItemResult key={r.id} {...r} />;
      })}
    </div>
  );
};

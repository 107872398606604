import React from "react";

import moment from "moment";
import { MobileCalendar } from "./MobileCalendar";

export const InputDiaCeremonia = ({ dia, setDia }) => {
  return (
    <MobileCalendar
      fecha={dia}
      setFecha={setDia}
      minDate={moment().format()}
      //Maximo hasta el fin de semana
      maxDate={
        moment().day() === 7
          ? moment().day(14).format()
          : moment().day(7).format()
      }
    />
  );
};

import React from "react";
import { AccordionDetails, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: "#525252",
  fontWeight: 600,
}));

export const Accordion_item = ({ content }) => {
  return (
    <Grid xs={12} sm={6} md={2}>
      <Item>{content}</Item>
    </Grid>
  );
};

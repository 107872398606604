import moment from "moment";
import { useEffect, useState } from "react";

export const useInputHorario = (ceremonias, setHorario) => {
  const [hora_ceremonia, setHora_ceremonia] = useState([]);

  useEffect(() => {
    if (ceremonias) {
      setHora_ceremonia(
        ceremonias.map((c) => {
          return {
            hora: moment(c.horario, "HH:mm:ss").format("HH:mm"),
            id: c.id,
          };
        })
      );

      setHorario(null);
    }
  }, [ceremonias]);

  return { hora_ceremonia };
};

import React, { useEffect, useState } from "react";
import { Autocomplete, Button, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import moment from "moment";

import { SnackbarC } from "../../common/components/";
import { useSnackBar } from "../../common/hooks/useSnackBar";
import { useForm } from "../hooks/useForm";
import { useFetchCeremonias } from "../../hooks/useFetchCeremonias";
import { getCeremoniasByDate } from "../../helpers/getCeremonias";
import { useFetchPuestosCeremonia } from "../../hooks/useFetchPuestosCeremonia";
import { InputDiaCeremonia } from "../../common/components/InputDiaCeremonia";
import { InputHorario } from "../../common/components/InputHorario";
import { InputPuestos } from "../../common/components/InputPuestos";

export const Container_dia_puesto = () => {
  const [labelAlert, setLabelAlert] = useState("");

  const { dia, setDia, horario, setHorario, puesto, setPuesto } = useForm();
  const { open, handleOpen, handleClose } = useSnackBar();

  const navigate = useNavigate();

  const validar = () => {
    if (dia === null || horario === null || puesto === null) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <div className="div_container_dia_puesto">
        <div className="div_title_dia_puesto">
          <Typography variant="h4" fontWeight={700} color="primary">
            Encontrar sustituto
          </Typography>
        </div>
        <div className="inputs_filtro">
          <div className="div_ceremonia_horario">
            <div className="div_calendar">
              <InputDiaCeremonia dia={dia} setDia={setDia} />
            </div>
            <div className="div_horario">
              <InputHorario
                dia={dia}
                horario={horario}
                setHorario={setHorario}
                setLabelAlert={setLabelAlert}
                handleOpen={handleOpen}
              />
            </div>
          </div>
          <div className="div_puestos">
            <InputPuestos
              dia={dia}
              horario={horario}
              puesto={puesto}
              setPuesto={setPuesto}
              setLabelAlert={setLabelAlert}
              handleOpen={handleOpen}
            />
          </div>
        </div>

        <div className="div_buscar">
          <Button
            variant="contained"
            className="btn_buscar"
            color="primary"
            style={{ borderRadius: 20 }}
            onClick={() => {
              if (!validar()) {
                setLabelAlert("¡Primero completa todos los campos!");
                handleOpen();
                return;
              }
              // Navegar a la página de buscar
              navigate(`/search/${dia}/${horario.hora}/${puesto.id}`);
            }}
          >
            <Typography
              variant="h4"
              fontWeight={500}
              color={"white.main"}
              textTransform={"capitalize"}
            >
              Buscar
            </Typography>
          </Button>
        </div>
      </div>

      <SnackbarC
        open={open}
        handleClose={handleClose}
        label={labelAlert}
        type="info"
      />
    </>
  );
};

import { Navigate, Route, Routes } from "react-router-dom";

import { HomePage } from "../HomePage";
import { Info_ac_page } from "../info_ac_page/pages/";
import { FindPage } from "../Find_sustituto_page";
import { Navbar } from "../ui";

export const PrivateRoutes = ({ isLogged }) => {
  return (
    <>
      {!isLogged && <Navigate to="/login" />}

      <Navbar />

      <Routes>
        <Route path="/*" element={<HomePage />} />
        <Route path="acolito/:id" element={<Info_ac_page />} />
        <Route path="search/:date/:horario/:puesto" element={<FindPage />} />
      </Routes>
    </>
  );
};

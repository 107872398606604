import { url } from "../api/url";

export const getPeriodoAusenteAc = async (id_acolito, startDate, endDate) => {
  try {
    const resp = await fetch(
      `${url}/acolitos/getPeriodosAusentes.php?id=${id_acolito}&start_date=${startDate}&end_date=${endDate}`
    );

    const data = await resp.json();

    return data;
  } catch (error) {
    console.log("Error al obtener el periodo ausente del acolito", error);
  }
};

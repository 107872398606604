import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { AvatarC } from "../components/AvatarC";
import { useFetchAcolito } from "../../hooks/useFetchAcolito";
import { SkeletonC } from "../components/SkeletonC";
import { BtnCall } from "../components/BtnCall";
import { Tabs } from "../components/Tabs";
import useScreenSize from "../../hooks/useScreenSize";
import { getAcolitoById } from "../../helpers/getAcolitos";
import { useFetchPeriodoAusente } from "../../hooks/useFetchPeriodoAusente";
import moment from "moment";

import "./info_ac_page.css";

export const Info_ac_page = () => {
  //Agregarle nombre a la pestaña

  const { id: id_acolito } = useParams();

  const { height_screen } = useScreenSize();

  const { loading, data: acolito } = useFetchAcolito(
    id_acolito,
    getAcolitoById
  );

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [periodo_ausente, setPeriodo_ausente] = useState([]);

  const fecha_actual = moment().format("YYYY-MM-DD");

  const fecha_fin_semana =
    moment().day() === 7
      ? moment().day(14).format("YYYY-MM-DD")
      : moment().day(7).format("YYYY-MM-DD");

  const { data: data_periodo, loading: loading_periodo_ausencia } =
    useFetchPeriodoAusente(id_acolito, fecha_actual, fecha_fin_semana);

  const fullName = `${acolito.nombre} ${acolito.apellido_p} ${acolito.apellido_m}`;

  document.title = "ASE - " + fullName;

  useEffect(() => {
    if (data_periodo.length > 0) {
      const periodo_ausente = data_periodo.map((periodo) => {
        const { fecha_inicio, fecha_termino } = periodo;
        const start_date_splitted = fecha_inicio.split(" ");
        const end_date_splitted = fecha_termino.split(" ");

        //Convertirla en formato DD/MM/YYYY
        const start_date = start_date_splitted[0].split("-");
        const end_date = end_date_splitted[0].split("-");
        const start_date_formatted = `${start_date[2]}/${start_date[1]}/${start_date[0]}`;
        const end_date_formatted = `${end_date[2]}/${end_date[1]}/${end_date[0]}`;

        return {
          start_date: start_date_formatted,
          end_date: end_date_formatted,
        };
      });

      setPeriodo_ausente(...periodo_ausente);

      handleOpen();
    }
  }, [data_periodo]);

  if (loading === true) {
    return <SkeletonC />;
  }

  let frase = "";

  // Obtener la fecha siguiente a la fecha de termino de ausencia
  const fecha_reingreso = moment(periodo_ausente.end_date, "DD/MM/YYYY")
    .add(1, "days")
    .format("DD/MM/YYYY");

  //Si la fecha start_date es menor a la fecha actual

  console.log("FECHA INICIO", moment(periodo_ausente.start_date));

  console.log("FECHA ACTUAL", moment());

  if (moment(periodo_ausente.start_date).isBefore(moment())) {
    frase = `no está disponible para acolitar desde el día ${periodo_ausente.start_date}. Se reincorporará hasta el día ${fecha_reingreso}.`;
  } else {
    frase = `no estará disponible para acolitar desde el día ${periodo_ausente.start_date}. Se reincorporará hasta el día ${fecha_reingreso}.`;
  }

  return (
    <>
      <div className="container_info" style={{ minHeight: height_screen }}>
        <AvatarC name={fullName} />
        <BtnCall phoneNumber={acolito.celular} />
        <Tabs acolito={acolito} />
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            color="primary"
            fontWeight={600}
            fontSize={20}
          >
            AVISO
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, textAlign: "justify" }}
          >
            {acolito.nombre} {frase}
          </Typography>
          <div
            style={{
              marginTop: "1rem",
              display: "flex",
              alignItems: "right",
              width: "100%",
              justifyContent: "right",
            }}
          >
            <Button onClick={handleClose}>Aceptar</Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
  paddingBottom: "1rem",
  outline: "none",
};

import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";

import { MobileDatePicker } from "@mui/x-date-pickers";

export function MobileCalendar({ fecha = null, setFecha, minDate, maxDate }) {
  const [fechaState, setFechaState] = useState(fecha);

  return (
    <MobileDatePicker
      label="Día de la Ceremonia"
      value={fechaState}
      onChange={(newValue) => {
        if (newValue) {
          setFechaState(newValue.format());
        }
      }}
      renderInput={(params) => <TextField {...params} />}
      style={{
        marginBottom: 50,
      }}
      minDate={minDate}
      //Maximo hasta el fin de semana
      maxDate={maxDate}
      onAccept={() => {
        setFecha(fechaState);
      }}
      //Cuando se cancela la seleccion de fecha
      onCancel={() => {
        console.log("Cancelado");
      }}
    />
  );
}

import React from "react";
import { Routes, Route } from "react-router-dom";

import { LoginPage } from "../auth/pages";
import { PrivateRoutes } from "./PrivateRoutes";

export const AppRouter = () => {
  const isLogged = true;
  return (
    <Routes>
      <Route path="login" element={<LoginPage />} />
      <Route path="/*" element={<PrivateRoutes isLogged={isLogged} />} />
    </Routes>
  );
};

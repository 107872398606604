import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useFetchPuestosCeremonia } from "../../hooks/useFetchPuestosCeremonia";
import { useInputPuestos } from "../hooks/useInputPuestos";

export const InputPuestos = ({
  handleOpen,
  setLabelAlert,
  dia,
  horario,
  puesto,
  setPuesto,
}) => {
  const { data: puestos_disponibles, loading: loading_puestos } =
    useFetchPuestosCeremonia(dia, horario);

  const { puesto_ceremonia } = useInputPuestos(puestos_disponibles, setPuesto);

  return (
    <Autocomplete
      noOptionsText="Sin resultados"
      options={puesto_ceremonia}
      getOptionLabel={(option) => option.puesto}
      style={{ width: 180 }}
      disabled={horario ? false : true}
      value={puesto}
      loadingText="Cargando..."
      loading={loading_puestos}
      onChange={(event, newValue) => {
        setPuesto(newValue);
      }}
      renderInput={(params) => (
        <TextField
          label="Puesto"
          variant="outlined"
          {...params}
          onClick={() => {
            if (!dia) {
              setLabelAlert("¡Primero selecciona una fecha!");
              handleOpen();
            } else if (!horario) {
              setLabelAlert("¡Primero selecciona una horario!");
              handleOpen();
            }
          }}
        />
      )}
    />
  );
};

import React from "react";

import { CircularProgress, Typography } from "@mui/material";

import { useFetchPuestosAcolito } from "../../../hooks/useFetchPuestosAcolito";
import { Accordion_C } from "./Accordion_C";
import { No_results } from "../../../common/components/No_results";

export const Puestos = ({ id_acolito }) => {
  const { data: puestos, loading } = useFetchPuestosAcolito(id_acolito);

  if (loading === true) {
    return (
      <div className="div_loading_tabs">
        <CircularProgress />
      </div>
    );
  }

  if (puestos === undefined || puestos.length === 0) {
    return <No_results />;
  }

  //Separar los puestos por tipo
  const puestos_por_tipo = puestos.reduce((acc, puesto) => {
    const { tipo_ceremonia } = puesto;

    if (!acc[tipo_ceremonia]) {
      acc[tipo_ceremonia] = [];
    }

    acc[tipo_ceremonia].push(puesto);

    return acc;
  }, {});

  //Obtener los puesto por tipo de ceremonia
  let puestos_por_tipo_array = Object.values(puestos_por_tipo);

  const ceremonias_ordenadas = [
    "Hora Santa",
    "Misa Rezada",
    "Misa Cantada",
    "Misa Solemne",
  ];

  let puestos_por_tipo_ordenados_array = [];

  ceremonias_ordenadas.forEach((ceremonia) => {
    puestos_por_tipo_array.forEach((puesto) => {
      if (puesto[0].tipo_ceremonia === ceremonia) {
        puestos_por_tipo_ordenados_array.push(puesto);
      }
    });
  });

  return (
    <div>
      <div className="div_txt_puestos">
        <Typography fontWeight={400} variant="body" color={"secondary.dark"}>
          Puestos que sabe acolitar
        </Typography>
      </div>
      {puestos_por_tipo_ordenados_array.map((p) => (
        <Accordion_C key={p[0].id} Title={p[0].tipo_ceremonia} {...p} />
      ))}
    </div>
  );
};

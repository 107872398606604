import React from "react";
import { Button, Fab, Icon, Typography } from "@mui/material";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

export const BtnCall = ({ label = "Contactar", phoneNumber }) => {
  return (
    <div className="div_btn_call_Whts">
      <Button
        href={`tel:${phoneNumber}`}
        variant="contained"
        sx={{
          borderRadius: 200,
          display: "flex",
          alignItems: "center",
          // marginTop: 10,
          // padding: 0,
          // marginLeft: 3,
          marginRight: 3,
        }}
      >
        <Typography
          variant="h4"
          style={{ textTransform: "capitalize", marginRight: 20 }}
          fontWeight={700}
        >
          {label}
        </Typography>
        <Icon component={PhoneEnabledIcon} />
      </Button>
      <Fab
        sx={styles.btn_whatsapp}
        target="_blank"
        href={`https://api.whatsapp.com/send?phone=${phoneNumber}`}
      >
        <WhatsAppIcon sx={{ color: "white.main", fontSize: 30, padding: 0 }} />
      </Fab>
    </div>
  );
};

const styles = {
  btn_whatsapp: {
    backgroundColor: "#25D366",
    color: "white",
    "&:hover": {
      backgroundColor: "#128C7E",
    },

    display: "flex",

    width: 50,
    height: 50,
    padding: 0,
    // marginTop: 2,
    borderRadius: 500,
    // padding: 1.5,
    // position: "fixed",
    // bottom: 10,
    // left: "50%",
    // transform: "translateX(-50%)",
  },
};

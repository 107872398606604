import React from "react";
import { Typography } from "@mui/material";

export const Main_text = () => {
  return (
    <div className="div_dir_text">
      <Typography
        variant="h1"
        fontWeight={700}
        color="primary"
        className="main_text"
      >
        Directorio de Acólitos
      </Typography>
      <Typography
        variant="h5"
        fontWeight={700}
        color="secondary"
        className="main_text"
      >
        Archicofradía de San Esteban
      </Typography>
    </div>
  );
};

import React from "react";

import moment from "moment";
import "moment/locale/es";
import { BrowserRouter as Router } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { AppRouter } from "./router/AppRouter";
import { mainTheme } from "./Theming/theme";

function App() {
  return (
    <LocalizationProvider
      adapterLocale={moment.locale()}
      dateAdapter={AdapterMoment}
    >
      <ThemeProvider theme={mainTheme}>
        <CssBaseline />
        <Router>
          <AppRouter />
        </Router>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;

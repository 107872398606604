import Skeleton from "@mui/material/Skeleton";

export function Loading({ items = 3 }) {
  const itemsArray = Array(items).fill(0);

  return (
    <>
      {itemsArray.map((item, index) => (
        <div key={index} className="div_filterBar_container">
          <div className="div_item">
            <Skeleton
              variant="circular"
              style={{
                width: 70,
                height: 70,
              }}
              animation="pulse"
            />
            <div className="div_renglones_item">
              <Skeleton
                variant="h3"
                width={"95%"}
                height={20}
                style={{ borderRadius: 20 }}
                animation="pulse"
              />
              <Skeleton
                variant="button"
                width={"80%"}
                height={15}
                style={{ borderRadius: 30, marginTop: 10 }}
                animation="pulse"
              />
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

import { url } from "../api/url";

export const getPuestosAcolito = async (id_acolito) => {
  try {
    const resp = await fetch(
      `${url}/acolitos/getConocimientoPuestos.php?id=${id_acolito}`
    );
    const data = await resp.json();
    return data.data;
  } catch (error) {
    console.log("Error al obtener los puestos", error);
  }
};

export const getPuestosByCeremonia = async (dia, hora) => {
  try {
    const resp = await fetch(
      `${url}/puestos/getByCeremonia.php?dia=${dia}&hora=${hora}`
    );
    const data = await resp.json();
    return data;
  } catch (error) {
    console.log("Error al obtener los puestos", error);
  }
};

export const getPuestoById = async (id_puesto) => {
  try {
    const resp = await fetch(`${url}/puestos/getById.php?id=${id_puesto}`);
    const data = await resp.json();
    return data;
  } catch (error) {
    console.log("Error al obtener el puesto", error);
  }
};

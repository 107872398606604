import React, { useState } from "react";

export const useSnackBar = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return {
    open,
    handleOpen,
    handleClose,
  };
};

import Skeleton from "@mui/material/Skeleton";

export function SkeletonC() {
  return (
    <div className="container_info">
      <div className="div_avatar">
        <Skeleton variant="circular" id="avatar" animation="wave" />
        <Skeleton
          variant="h3"
          width={"80%"}
          height={35}
          className="nombre_acolito"
          style={{ borderRadius: 20 }}
          animation="wave"
        />
        <Skeleton
          variant="button"
          width={"100%"}
          height={50}
          className="nombre_acolito"
          style={{ borderRadius: 30 }}
          animation="wave"
        />
      </div>
    </div>
  );
}

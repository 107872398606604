import { url } from "../api/url";

export const getAcolitosByRol = async (id_dia, dia, hora, puesto) => {
  console.log(id_dia, dia, hora, puesto);
  try {
    const resp = await fetch(
      `${url}/acolitos/getByRol.php?id_dia=${id_dia}&dia=${dia}&hora=${hora}&puesto=${puesto}`
    );
    const data = await resp.json();
    return data;
  } catch (error) {
    console.log("Error al obtener los acolitos disponibles", error);
  }
};

import * as React from "react";
import { Stack, Button, Snackbar, Alert } from "@mui/material";

export function SnackbarC({
  open,
  auto_hide_duration = 2500,
  handleClose,
  label,
  type,
}) {
  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={open}
        autoHideDuration={auto_hide_duration}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={type}
          sx={{ width: "100%" }}
          color="error"
        >
          {label}
        </Alert>
      </Snackbar>
    </Stack>
  );
}

import { Avatar, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export const ItemResult = ({
  id,
  nombre_completo,
  cargo,
  nombre,
  apellido_p,
}) => {
  const navigate = useNavigate();

  function stringAvatar() {
    const iniciales = `${nombre[0]}${apellido_p[0]}`;
    return iniciales;
  }

  return (
    <>
      <div
        className="div_item"
        onClick={() => {
          navigate(`/acolito/${id}`);
        }}
      >
        <div className="div_avatar_item">
          <Avatar
            style={{ width: 70, height: 70, fontSize: 30, fontWeight: 700 }}
          >
            {stringAvatar()}
          </Avatar>
        </div>
        <div className="div_renglones_item">
          <Typography variant="h6" color={"primary"}>
            {nombre_completo}
          </Typography>
          <Typography variant="body1" color={"secondary.dark"}>
            {cargo}
          </Typography>
        </div>
      </div>
    </>
  );
};

import React from "react";

import { CircularProgress, Typography } from "@mui/material";

import { getDisponibilidad } from "../../../helpers/getDisponibilidad";
import { useFetchAcolito } from "../../../hooks/useFetchAcolito";
import { Dia } from "./Dia";
import { No_results } from "../../../common/components/No_results";

export const Disponibilidad = ({ id_acolito, nombre_acolito }) => {
  const { data: horario, loading } = useFetchAcolito(
    id_acolito,
    getDisponibilidad
  );

  if (loading === true) {
    return (
      <div className="div_loading_tabs">
        <CircularProgress />
      </div>
    );
  }

  if (horario === undefined || horario.length === 0) {
    return <No_results />;
  }

  //regresar un arreglo por cada dia único
  const dias = horario.reduce((acc, cur) => {
    if (!acc.includes(cur.dia)) {
      acc.push(cur.dia);
    }
    return acc;
  }, []);

  let horario_por_dia = [];

  dias.forEach((dia) => {
    let aux = [];
    horario.forEach((horario) => {
      if (dia === horario.dia) {
        aux.push(horario);
      }
    });
    horario_por_dia.push(aux);
  });

  const dias_semana = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];

  horario_por_dia = horario_por_dia.sort((a, b) => {
    return dias_semana.indexOf(a[0].dia) - dias_semana.indexOf(b[0].dia);
  });

  return (
    <div>
      <div className="div_txt_puestos">
        <Typography variant="body" color={"secondary.dark"}>
          {nombre_acolito} puede acolitar en los siguientes horarios
        </Typography>
      </div>
      {horario_por_dia.map((horario) => {
        return <Dia key={horario[0].dia} horario={horario} />;
      })}
    </div>
  );
};

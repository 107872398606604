import React from "react";
import { Tooltip, Typography, useMediaQuery } from "@mui/material";
import { Logout } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { imagenes } from "../../../helpers/getImagenes";

import { colors } from "../../colors";

import "./navbar.css";

export const Navbar = () => {
  const navigate = useNavigate();
  const mobile_screen = useMediaQuery("(max-width:550px)");

  return (
    <>
      <div className="container_nav" style={styles.container}>
        <div
          className="div_bienvenido"
          onClick={() => {
            navigate("/");
          }}
        >
          <Typography
            variant="h3"
            fontWeight={700}
            color="primary"
            className="bienvenido"
          >
            Bienvenido
          </Typography>
        </div>

        <div className="div_exit">
          {/* <Tooltip title="Cerrar sesión" placement="left"> */}
          {/* <Logout fontSize="large" color="action" /> */}
          <img
            src={imagenes("./medalla_roja.png")}
            alt="logo"
            className="medalla_roja_img_navbar"
          />
          {/* </Tooltip> */}
        </div>
      </div>
      {/* <div className="nav_bar_size"></div> */}
    </>
  );
};
const styles = {
  container: {
    backgroundColor: colors.gray_light,
    // position: "fixed",
    // top: 0,
    // width: "100%",
    // zIndex: 100,
  },
};

import React, { useEffect, useState } from "react";
import { getPeriodoAusenteAc } from "../helpers/getPeriodoAusenteAc";

export const useFetchPeriodoAusente = (id_acolito, start_date, end_date) => {
  const [state, setState] = useState({
    data: [],
    loading: true,
  });

  useEffect(() => {
    getPeriodoAusenteAc(id_acolito, start_date, end_date).then((r) => {
      setState({
        data: r,
        loading: false,
      });
    });
  }, []);

  return state;
};

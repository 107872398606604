import { useEffect, useState } from "react";

export const useFetchAcolito = (id_acolito, helper) => {
  const [state, setState] = useState({
    data: [],
    loading: true,
  });

  useEffect(() => {
    helper(id_acolito).then((r) => {
      setState({
        data: r,
        loading: false,
      });
    });
  }, []);

  return state;
};

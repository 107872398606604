import moment from "moment";
import React, { useEffect, useState } from "react";

export const useForm = () => {
  const [dia, setDia] = useState(moment().format("YYYY-MM-DD"));
  const [horario, setHorario] = useState(null);
  const [puesto, setPuesto] = useState(null);

  useEffect(() => {
    setPuesto(null);
  }, [horario]);

  useEffect(() => {
    setHorario(null);
  }, [dia]);

  return {
    dia,
    setDia,
    horario,
    setHorario,
    puesto,
    setPuesto,
  };
};

import { useEffect, useState } from "react";
import { getPuestoById } from "../helpers/getPuestos";

export const useFetchPuesto = (puesto) => {
  const [state, setState] = useState({
    data: [],
    loading: true,
  });

  useEffect(() => {
    getPuestoById(puesto).then((puesto) => {
      setState({
        data: puesto,
        loading: false,
      });
    });
  }, []);

  return state;
};

import { url } from "../api/url";

export const getAcolitos_activos = async () => {
  try {
    const resp = await fetch(`${url}/acolitos/get.php?active=true`);
    const data = await resp.json();
    return data.data;
  } catch (error) {
    console.log("Error al obtener los acolitos", error);
  }
};

export const getAcolitos = async () => {
  try {
    const resp = await fetch(`${url}/acolitos/get.php`);
    const data = await resp.json();
    return data;
  } catch (error) {
    console.log("Error al obtener los acolitos", error);
  }
};

export const getAcolitoById = async (id) => {
  try {
    const resp = await fetch(`${url}/acolitos/getById.php?id=${id}`);
    const data = await resp.json();
    return data;
  } catch (error) {
    console.log("Error al obtener la info del acólito", error);
  }
};

import { useEffect, useState } from "react";

import { getPuestosByCeremonia } from "../helpers/getPuestos";

export const useFetchPuestosCeremonia = (fecha, hora) => {
  const [state, setState] = useState({
    data: [],
    loading: true,
  });

  const orden_puestos = [
    "Maestro de Ceremonias",
    "Turiferario",
    "Acólito 1",
    "Acólito 2",
    "CRUZ",
    "ACHA 1",
    "ACHA 2",
    "ACHA 3",
    "ACHA 4",
  ];

  useEffect(() => {
    if (fecha && hora) {
      getPuestosByCeremonia(fecha, hora.hora).then((puestos) => {
        const puestos_ordenados = puestos.sort((a, b) => {
          return (
            orden_puestos.indexOf(a.nombre) - orden_puestos.indexOf(b.nombre)
          );
        });
        setState({
          data: puestos_ordenados,
          loading: false,
        });
      });
    }
  }, [hora]);

  return state;
};

import { useEffect, useState } from "react";

export const useFetchCeremonias = (fecha, helper) => {
  const [state, setState] = useState({
    data: [],
    loading: true,
  });

  useEffect(() => {
    helper(fecha).then((c) => {
      setState({
        data: c,
        loading: false,
      });
    });
  }, [fecha]);

  return state;
};

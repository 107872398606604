import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useInputHorario } from "../hooks/useInputHorario";
import { useFetchCeremonias } from "../../hooks/useFetchCeremonias";
import { getCeremoniasByDate } from "../../helpers/getCeremonias";
import moment from "moment";

export const InputHorario = ({
  handleOpen,
  setLabelAlert,
  dia,
  horario,
  setHorario,
}) => {
  const { data: ceremonias, loading: loading_horarios } = useFetchCeremonias(
    dia,
    getCeremoniasByDate
  );

  const [horarios, setHorarios] = useState([]);

  useEffect(() => {
    setHorarios(
      ceremonias.filter((ceremonia) =>
        moment(ceremonia.dia).format("YYYY-MM-DD") ===
        moment().format("YYYY-MM-DD")
          ? ceremonia.horario > moment().format("HH:mm:ss")
            ? true
            : false
          : true
      )
    );
  }, [ceremonias]);

  const { hora_ceremonia } = useInputHorario(horarios, setHorario);

  return (
    <Autocomplete
      noOptionsText="Sin resultados"
      options={hora_ceremonia}
      getOptionLabel={(option) => option.hora}
      style={{ width: 150 }}
      disabled={dia ? false : true}
      value={horario}
      onChange={(event, newValue) => {
        setHorario(newValue);
      }}
      loadingText="Cargando..."
      loading={loading_horarios}
      renderInput={(params) => (
        <TextField
          label="Horario"
          variant="outlined"
          {...params}
          onClick={() => {
            if (!dia) {
              if (handleOpen) {
                setLabelAlert("¡Primero selecciona una fecha!");
                handleOpen();
              }
            }
          }}
        />
      )}
    />
  );
};

import { createTheme, responsiveFontSizes } from "@mui/material";

export let mainTheme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: "#b22222",
      dark: "#85130b",
      light: "#eb584b",
      contrastText: "#fff",
    },
    secondary: {
      main: "#bfbfbf",
      light: "#f2f2f2",
      dark: "#8f8f8f",
      contrastText: "#000",
    },
    warning: {
      main: "#ffff66",
    },
    success: {
      main: "#009900",
    },
    error: {
      main: "#ff0000",
    },
    white: {
      main: "#fff",
    },
  },
  typography: {
    fontFamily: ["Plus Jakarta Sans", "-apple-system"].join(","),
  },
});

mainTheme = responsiveFontSizes(mainTheme);

import React, { useEffect, useState } from "react";
import { getAcolitosByRol } from "../helpers/getAcolitosByRol";

export const useFetchAcolitosByRol = (id_dia, dia, hora, puesto) => {
  const [state, setState] = useState({
    data: [],
    loading: true,
  });

  useEffect(() => {
    getAcolitosByRol(id_dia, dia, hora, puesto).then((r) => {
      setState({
        data: r,
        loading: false,
      });
    });
  }, []);

  return state;
};

import React from "react";
import { Box, Typography } from "@mui/material";

export const H_misa = ({ horario }) => {
  //No mostrar los segundos en la hora
  const hora = horario.hora.split(":");
  hora.pop();
  const hora_sin_segundos = hora.join(":");
  return (
    <>
      <Box
        className="div_hora"
        boxShadow={5}
        sx={{
          backgroundColor: "secondary.light",
        }}
      >
        <Typography variant="h5" color={"primary.main"}>
          {hora_sin_segundos}
        </Typography>
      </Box>
    </>
  );
};

import moment from "moment";
import { useEffect, useState } from "react";

export const useInputPuestos = (puestos, setPuesto) => {
  const [puesto_ceremonia, setPuesto_ceremonia] = useState([]);

  useEffect(() => {
    setPuesto_ceremonia(
      puestos.map((p) => {
        return {
          puesto: p.nombre,
          id: p.id,
        };
      })
    );
    setPuesto(null);
  }, [puestos]);

  return { puesto_ceremonia };
};

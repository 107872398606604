import React, { useEffect, useState } from "react";

import { Main_text } from "../components/Main_text";
import { SearchBar } from "../components/SearchBar";
import { Container_dia_puesto } from "../components/Container_dia_puesto";

import "./HomePage.css";

export const HomePage = () => {
  document.title = "Directorio de Acólitos";

  return (
    <div className="container">
      <Main_text />

      <SearchBar />

      <Container_dia_puesto />
    </div>
  );
};
